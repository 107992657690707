import React from 'react';
import { useTranslation } from 'react-i18next';
import PspComposer from 'lib/components/PspComposer';
import CallbackPaymentBlock from 'lib/components/CallbackPaymentBlock';

import lockIcon from 'lib/assets/lock.svg';
import weezIcon from 'lib/assets/weez.svg';

import Loader from 'lib/components/Loader';
import styles from './PaymentPage.module.scss';

const PaymentPage = () => {
    const { t } = useTranslation();
    const integrationRef = React.useRef();
    const lyriaUrl = window.weezConfig?.lyriaUrl ?? 'http://localhost:8003';

    const [displayPaymentDone, setDisplayPaymentDone] = React.useState(false);
    const [isPaymentSuccess, setIsPaymentSuccess] = React.useState();
    const [redirectURL, setRedirectURL] = React.useState();
    const paymentRequestUUID = React.useMemo(() => {
        const path = window.location.pathname;
        const parts = path.split('/');
        return parts[parts.length - 1];
    }, []);


    const [loader, setLoader] = React.useState(<Loader />);

    const redirectOnPaymentResult = React.useCallback((_redirectURL) => {
        const url = typeof (_redirectURL) === 'string' ? _redirectURL : redirectURL;
        return window.location.assign(url);
    }, [redirectURL]);

    const onPaymentResult = React.useCallback((res) => {
        setLoader(null);
        setIsPaymentSuccess(res.success);
        if (res.data) {
            setDisplayPaymentDone(true);
        }
        if (res.data?.callback_url) {
            setRedirectURL(res.data?.callback_url);
            if (res.success) {
                const fifteenSeconds = 15000;
                setTimeout(() => {
                    redirectOnPaymentResult(res.data?.callback_url);
                }, fifteenSeconds);
            } else {
                const shouldRedirect = !['RetryWithTheSameCard', 'RetryWithAnotherCard', 'CheckYourCardDetails'].includes(res.data.message);
                if (shouldRedirect) {
                    const thirteenSeconds = 30000;
                    setTimeout(() => {
                        redirectOnPaymentResult(res.data?.callback_url);
                    }, thirteenSeconds);
                }
            }
        }
    }, [setRedirectURL, setIsPaymentSuccess, setDisplayPaymentDone, redirectOnPaymentResult]);

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.header_content}>
                    <h3 className={styles.bluetext}>{t('paymentCheckout')}</h3>
                    <p>
                        <img src={lockIcon} alt="lock" className={styles.lock_icon}></img>
                        {t('securePayment')}
                    </p>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.content_left}>
                    <div className={styles.payment}>
                        <div className={styles.payment_method}>
                            <PspComposer
                                ref={integrationRef}
                                loader={loader}
                                lyriaUrl={lyriaUrl}
                                paymentRequestId={paymentRequestUUID}
                                onPaymentResult={onPaymentResult}
                            />
                            {displayPaymentDone && (
                                <CallbackPaymentBlock
                                    isPaymentSuccess={isPaymentSuccess}
                                    redirectURL={redirectURL}
                                    redirectOnPaymentResult={redirectOnPaymentResult}
                                />
                            )}
                            <p>{t('paymentSecurityStandard')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.footer}>
                <p>
                    <img src={lockIcon} alt="lock" className={styles.footer_lock_icon}></img>
                    {t('securePayment')}
                </p>
                <p>{t('secureProtocolInfo')}</p>
                <div className={styles.poweredby}>
                    <img src={weezIcon} alt="weezevent"></img>
                    <p>{t('poweredBy')}</p>
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;
